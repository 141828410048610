@sm-breakpoint: 576px;
@md-breakpoint: 768px;
@md-breakpoint-max: 769px;
@lg-breakpoint: 992px;
@xl-breakpoint-max: 1199px;
@xl-breakpoint: 1200px;
@xxl-breakpoint: 1600px;
@xxxl-breakpoint-max: 1919px;
@xxxl-breakpoint: 1920px;
@xxxxl-breakpoint: 2400px;

@header-xxl: 110px;
@header-xl: 80px;
@header-lg: 80px;
@header-sm: 69px;

@footer-xxl: 110px;
@footer-xl: 80px;
@footer-lg: 80px;

.container {
  max-width: 100vw;
}


.container {
  padding: 60px !important;
  min-height: calc(100vh - @header-xxl - @footer-xxl);
}


@media (max-width: @md-breakpoint) {
  .container {
    padding: 30px 20px;
  }
}

// Extra small devices (portrait phones, less than 576px)


@media (min-width: @lg-breakpoint) {
  .footer {
    height: @footer-lg;
  }
}

@media (max-width: @sm-breakpoint) {
  .container {
    min-height: calc(100vh - @header-sm - @footer-lg);
    display: block;
    transition: all 0.4s;
  }
}

@media (min-width: @sm-breakpoint) and (max-width: @lg-breakpoint) {
  .container {
    min-height: calc(100vh - @header-lg - @footer-xxl);
    display: block;
    transition: all 0.4s;
  }
}

@media (min-width: @lg-breakpoint) and (max-width: @xl-breakpoint) {
  .container {
    min-height: calc(100vh - @header-xl - @footer-lg);
    display: block;
    transition: all 0.4s;
  }
}

@media (min-width: @xl-breakpoint) {
  .container {
    min-height: calc(100vh - @header-xxl - @footer-xxl);
    display: block;
    transition: all 0.4s;
  }
}


@media (max-width: 1024px) {
  .container {
    padding: 42px !important;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 32px !important;
  }
}

@media (max-width: 375px) {
  .container {
    padding: 20px !important;
  }
}

