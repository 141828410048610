button {
  outline: none !important;
}

a {
  color: @markant-red;
}

a:hover {
  color: @markant-red;
  text-decoration: none;
}

.white-button {
  border: 1px solid grey;
  margin-top: 40px;
  margin-right: 20px;
  float: right;
}

.red-button {
  border: 1px solid grey;
  margin-top: 40px;
  margin-right: 20px;
  margin-bottom: 50px;
  color: white;
  float: right;
  background-color: @markant-red;
}
