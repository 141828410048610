 @font-face {
  font-family: "Gilroy";
  src: local('Gilroy-Regular'), url("fonts/Gilroy/Gilroy-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local('Gilroy-Bold'), url("./fonts/Gilroy/Gilroy-Bold.otf") format("opentype");
  font-weight: bold;
}

html, body {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-stretch: normal;
}

h1, h2, h3 {
  font-weight: bold;
}

.footer-link{
  font-size: 16px;
  line-height: 26px;
}