@white: rgb(255, 255, 255);
@black-with-opacity-3: rgba(0, 0, 0, 0.3);
@gray-51: rgb(51, 51, 51);
@gray-82: rgb(82, 82, 82);
@gray-88: rgb(88, 88, 88);
@gray-100: rgb(100,100,100);
@gray-204: rgb(204, 204, 204);
@gray-221: rgb(221, 221, 221);
@gray-242: rgb(242, 242, 242);
@gray-252: rgb(252, 252, 252);
@markant-red: #eb002d;
@anthrazit: rgb(35,35,35);
@dark-cyan: rgb(28, 118, 123);
@purple: #5a0037;
@red: #eb002d;
@dark-red: #5a0037;


a {
  color: @dark-red;
}

.sk-markant-red {
  circle {
    stroke: @markant-red;
  }
}

.text-link:hover{
  color: @markant-red;
}

.footer-link:hover{
  color: @markant-red;
}