//
// Custom Component Styling
//

// Define any styles affected by the theme.
.mat-snack-bar-container {
  background-color: #ffb2b2;
  color: #ff1d2c;
  .mat-simple-snackbar-action {
    background-color: #646464 !important;
    color: #FFFFFF;
  }
}
