@import "colors";
@import "variables";
@import "width-breakpoints";
@import "custom";
@import "fonts";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@markant/elements/dist/markant-elements/markant-elements.css';

html {
  height: 100%;

  body {
    height: fit-content;
    overflow-y: scroll;
    margin: @header-xxl 0 0 0;
    font-family: "Gilroy", sans-serif;
    font-size: 18px;
  }

  &.cdk-global-scrollblock {
    -ms-overflow-y: visible;
    overflow-y: initial;
  }
}

@media (max-width: @xl-breakpoint) {
  body {
    margin-top: @header-xl !important;
  }
}

@media (max-width: @lg-breakpoint) {
  body {
    margin-top: @header-lg !important;
  }
}

@media (max-width: @sm-breakpoint) {
  body {
    margin-top: @header-sm !important;
  }

  svg {
    vertical-align: top !important;
  }
}

.hide-step-header {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
  }

  mat-card {
    border-radius: 0;
  }
}

.admin-dialog-container .mat-dialog-container {
  overflow: hidden;
  position: relative;
  width: 1000px;
  margin: auto auto 50px;
}

.text-link {
  font-size: 18px;
  line-height: 24px;

  &:hover {
    color: #eb002d !important;
  }
}
/************************************************/
/* Scrollbar */
/************************************************/
::-webkit-scrollbar {
  width: 12px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: #a0a0a0 #e0e0e0;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}
::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 6px;
  border: 3px solid #e0e0e0;
}

.badge-content::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar {
  width: 6px;
}

.badge-content::-webkit-scrollbar-track,
.mat-select-panel::-webkit-scrollbar-track {
  background-color: transparent;
}

.badge-content::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: none;
}

.scrollbar-thumb {
  background: #a0a0a0 !important;
}

.scrollbar-track {
  background: #e0e0e0 !important;
}

.notification{
  fill: #008800;
}
/************************************************/
/* Dropdown Option */
/************************************************/

.mat-option,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #5a0037 !important;;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: none;
}

.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-option.mat-active {
  background: none;
  font-weight: bold;
}

.mat-select-panel {
  min-width: 390px !important;
  max-height: 200px !important;
}

.mat-select-panel-wrap {
  max-height: 224px !important;
  padding: 10px 5px;
  border-radius: 20px !important;
  border: solid 2px @purple;
  background-color: #ffffff ;
}
